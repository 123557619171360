.loading.cover-content {
	position: absolute;
	left: 50%;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}
.loading.cover-page {
	position: fixed;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}
.loading .ant-spin {
	color: #3e79f7;
}
.page-excerpt {
	font-size: 10px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 200px;
}

.custom-header-page-alt {
	padding: 25px;
	margin-top: -25px;
	margin-left: -25px;
	margin-right: -25px;
}

.custom-overlap {
	margin-bottom: -4.6875rem;
}

.upload-btn-container {
	position: absolute;
	right: 20px;
	bottom: 20px;
	z-index: 2;
}

@media only screen and (max-width: 768px) {
	.custom-header-page-alt {
		margin-left: -15px;
		margin-right: -15px;
	}

	.edit-profile {
		display: flex;
		flex-direction: column;
	}

	.upload-btn-container {
		display: flex;
		justify-content: center;
		z-index: 2;
		position: absolute;
		bottom: 20px;
		left: 0;
		right: 0;
		margin-left: auto;
		margin-right: auto;
		width: 100%;
	}

	.upload-btn-container button {
		font-size: 11px !important;
	}
}

.ant-menu-item-group{
    border-bottom:1px solid rgb(208, 212, 215) !important;
  }
.ant-menu-item-group-title{
	padding: 3px 24px !important;
}
